import React, { useCallback, useContext, useEffect, useState } from "react"
import {
  AppBar,
  Box,
  Button,
  FormControlLabel,
  FormGroup,
  IconButton,
  Stack,
  Switch,
  ToggleButton,
  ToggleButtonGroup,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material"
import HomeIcon from "@mui/icons-material/Home"
import PropTypes from "prop-types"
import { useNavigate } from "react-router-dom"
import useLocalStorage from "../../../manager/localStorage"
import { makeStyles } from "@mui/styles"
import { RootDataContext, ViewType } from "../index"
import { AuthDataContext } from "../../../App"
import { WindowMode } from "../data/state"
import {
  BorderHorizontalRounded as BorderHorizontalRoundedIcon,
  BorderVerticalRounded as BorderVerticalRoundedIcon,
} from "@mui/icons-material"
import excelIcon from "../../../resources/images/h_excel_on.png"
import hanreiIcon from "../../../resources/images/h_hanrei_on.png"
import shukeiIcon from "../../../resources/images/h_shukeihyou_on.png"
import jokenOnIcon from "../../../resources/images/h_joken_on.png"
import jokenOffIcon from "../../../resources/images/h_joken_off.png"
import dispListOnIcon from "../../../resources/images/h_hyoji_list_on.png"
import dispListOffIcon from "../../../resources/images/h_hyoji_list_off.png"
import dispMapOnIcon from "../../../resources/images/h_hyoji_map_on.png"
import dispMapOffIcon from "../../../resources/images/h_hyoji_map_off.png"
import shindanIcon from "../../../resources/images/h_shindan_on.png"
import verticalOnIcon from "../../../resources/images/h_gamen_tate_on.png"
import verticalOffIcon from "../../../resources/images/h_gamen_tate_off.png"
import horizontalOnIcon from "../../../resources/images/h_gamen_yoko_on.png"
import horizontalOffIcon from "../../../resources/images/h_gamen_yoko_off.png"
import menuIcon from "../../../resources/images/bueger_button.png"
import logoutIcon from "../../../resources/images/h_logout_on.png"

const useStyles = makeStyles({
  toggle: {
    color: "#526981 !important",
    borderColor: "#76a1cb !important",
  },
  toggleButton: {
    borderColor: "white",
    color: "#99bde2 !important",
    paddingLeft: "16px !important",
    paddingRight: "16px !important",
  },
  toggleButtonSelected: {
    backgroundColor: "#c8e0f7 !important",
    color: "#1e3176 !important",
    paddingLeft: "16px !important",
    paddingRight: "16px !important",
  },
})

const styles = {
  button: {
    height: "40px",
  },
  toggleButton: {
    height: "40px",
    margin: 0,
    padding: 0,
  },
}

const Header = (props) => {
  const { title } = props
  const classes = useStyles()
  const navigate = useNavigate()
  const { state, setFilterData, setViewType, setWindowMode } =
    useContext(RootDataContext)
  const { authState, logout } = useContext(AuthDataContext)
  const [isTitleHidden, setIsTitleHidden] = useState(false)

  useEffect(() => {
    console.log("AppBar", "auth", authState.userData)
  }, [authState.userData])

  useEffect(() => {
    window.addEventListener("resize", onReisze)
  }, [])

  const toHome = () => {
    navigate("/")
  }

  const onFilterReset = async () => {
    await setFilterData({})
  }

  const onChangeViewType = (event, newViewTypes) => {
    if (newViewTypes.length === 0) {
      return
    }
    setViewType(newViewTypes)
  }

  const onLogout = () => {
    authState.auth.signOut().then(() => {
      logout()
    })
  }

  const onOpenReceipt = () => {
    window.open("https://diagnostic.gairoju.jp/", "_blank")
  }

  const onChangeWindowMode = (e, value) => {
    console.log("[WindowMode]", "change", e, e.target.value, value)
    if (value) {
      setWindowMode(value)
    }
  }

  const onReisze = () => {
    console.log("[TitleHidden]", "check", window.innerWidth)
    setIsTitleHidden(window.innerWidth < 1540)
  }

  const toggleViewType = useCallback(
    (type) => {
      if (state.viewType.includes(type)) {
        setViewType(state.viewType.filter((v) => v !== type))
      } else {
        setViewType([...state.viewType, type])
      }
    },
    [state.viewType]
  )

  return (
    <Box>
      <AppBar
        position="static"
        style={{
          background:
            "linear-gradient(90deg, #69c865 0%, #0f7285 58.51%, #0d5c6d 100%)",
        }}
      >
        <Toolbar>
          <Typography
            variant="h5"
            component="div"
            fontWeight="bold"
            style={{
              whiteSpace: "nowrap",
              fontFamily: "Zen Kaku Gothic New",
              overflow: "hidden",
            }}
          >
            {title || process.env.REACT_APP_TITLE || "データベース"}
          </Typography>

          {!props.disableHomeButton && (
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="home"
              sx={{ mr: 2 }}
              onClick={toHome}
            >
              <img src={menuIcon} />
            </IconButton>
          )}
          <Box sx={{ flexGrow: 1 }} />
          {props.onExport && (
            <IconButton onClick={props.onExport}>
              <img src={excelIcon} alt="Excel出力" style={styles.button} />
            </IconButton>
          )}
          <IconButton onClick={props.onLegends}>
            <img src={hanreiIcon} alt="地図判例表示" style={styles.button} />
          </IconButton>
          {props.onSummary && (
            <IconButton onClick={props.onSummary}>
              <img src={shukeiIcon} alt="集計表表示" style={styles.button} />
            </IconButton>
          )}
          <IconButton onClick={onFilterReset}>
            <img src={jokenOnIcon} alt="条件クリア" style={styles.button} />
          </IconButton>
          <Box sx={{ m: 1 }} />
          <Box>
            {state.viewType.includes(ViewType.List) && (
              <IconButton
                style={styles.toggleButton}
                onClick={() => toggleViewType(ViewType.List)}
              >
                <img
                  src={dispListOnIcon}
                  alt="リスト表示"
                  style={styles.button}
                />
              </IconButton>
            )}
            {!state.viewType.includes(ViewType.List) && (
              <IconButton
                style={styles.toggleButton}
                onClick={() => toggleViewType(ViewType.List)}
              >
                <img
                  src={dispListOffIcon}
                  alt="リスト非表示"
                  style={styles.button}
                />
              </IconButton>
            )}
            {state.viewType.includes(ViewType.Map) && (
              <IconButton
                style={styles.toggleButton}
                onClick={() => toggleViewType(ViewType.Map)}
              >
                <img src={dispMapOnIcon} alt="地図表示" style={styles.button} />
              </IconButton>
            )}
            {!state.viewType.includes(ViewType.Map) && (
              <IconButton
                style={styles.toggleButton}
                onClick={() => toggleViewType(ViewType.Map)}
              >
                <img
                  src={dispMapOffIcon}
                  alt="地図非表示"
                  style={styles.button}
                />
              </IconButton>
            )}
          </Box>
          <IconButton onClick={onOpenReceipt}>
            <img
              src={shindanIcon}
              alt="診断カルテを開く"
              style={styles.button}
            />
          </IconButton>
          <Box>
            <IconButton
              disabled={state.windowMode === WindowMode.Horizontal}
              onClick={() => setWindowMode(WindowMode.Horizontal)}
              style={{ padding: 0 }}
            >
              <img
                style={styles.toggleButton}
                src={
                  state.windowMode === WindowMode.Horizontal
                    ? verticalOnIcon
                    : verticalOffIcon
                }
                alt="横分割"
              />
            </IconButton>
            <IconButton
              disabled={state.windowMode === WindowMode.Vertical}
              onClick={() => setWindowMode(WindowMode.Vertical)}
              style={{ padding: 0 }}
            >
              <img
                style={styles.toggleButton}
                src={
                  state.windowMode === WindowMode.Vertical
                    ? horizontalOnIcon
                    : horizontalOffIcon
                }
                alt="縦分割"
              />
            </IconButton>
          </Box>
          <Box sx={{ m: 1 }} />
          <IconButton onClick={onLogout}>
            <img src={logoutIcon} alt="ログアウト" style={styles.button} />
          </IconButton>
        </Toolbar>
      </AppBar>
    </Box>
  )
}

Header.propTypes = {
  title: PropTypes.string,
  children: PropTypes.element,
  disableHomeButton: PropTypes.bool,
  onChangeViewMode: PropTypes.func,
  onSummary: PropTypes.func,
  onLegends: PropTypes.func,
  onExport: PropTypes.func,
}

export default Header
