import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import { makeStyles } from "@mui/styles"
import { Box, Button, TextField, Typography } from "@mui/material"
import { StyledFirebaseAuth } from "react-firebaseui"
import { getAuth, signInWithEmailAndPassword } from "firebase/auth"
import firebase from "firebase/compat/app"
import "firebase/compat/auth"
import PropTypes from "prop-types"
import { AuthDataContext } from "../../App"
import TitleBgImg from "../../resources/images/title_bg.png"
import "./login.css"

const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: "popup",
  // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
  // We will display Google and Facebook as auth providers.
  signInOptions: [firebase.auth.GoogleAuthProvider.PROVIDER_ID],
}

const useStyles = makeStyles({
  root: {
    background: "#227D7E",
    width: "100vw",
    height: "100vh",
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
  },
  titleBox: {
    backgroundImage: `url(${TitleBgImg})`,
    backgroundPosition: "center",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "1rem",
    height: "210px",
  },
  titleText: {
    fontSize: "24px",
    fontWeight: "bold",
    color: "#fff",
  },
})

const styles = {
  titleText: {
    fontSize: "30px",
    fontWeight: "bold",
    color: "#fff",
  },
  input: {
    background: "white",
    borderRadius: "16px",
    width: "360px",
  },
  loginButton: {
    marginTop: "1rem",
    width: "360px",
    background: "#4eb264",
    borderRadius: "16px",
    height: "45px",
    color: "white",
  },
}

const LoginView = (props) => {
  const classes = useStyles()
  const [showPassword, setShowPassword] = useState(false)
  const { authState } = useContext(AuthDataContext)
  const [error, setError] = useState()
  const [password, setPassword] = useState("")
  const [email, setEmail] = useState("")

  const auth = useMemo(() => getAuth(), [])

  const passwordAuth = () => {
    signInWithEmailAndPassword(auth, email, password)
      .then((credential) => {
        console.log(credential)
      })
      .catch((e) => {
        console.log(e.name, e.message, e.code)
        setError(e)
      })
  }

  useEffect(() => {
    console.log("[Login]", window.location.href)
  }, [])

  useEffect(() => {
    setError(null)
  }, [email, password])

  return (
    <Box className={classes.root}>
      <Box className={classes.titleBox}>
        <Typography style={styles.titleText}>
          東京都街路樹台帳データベース
        </Typography>
      </Box>
      {error &&
        ["auth/user-not-found", "auth/wrong-password"].includes(error.code) && (
          <Typography variant="subtitle2" sx={{ color: "#f1aee4" }}>
            メールアドレスまたはパスワードが間違っています
          </Typography>
        )}
      {error &&
        !["auth/user-not-found", "auth/wrong-password"].includes(
          error.code
        ) && (
          <Typography variant="subtitle2" sx={{ color: "#f1aee4" }}>
            不明なエラー({error.code})
          </Typography>
        )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            maxWidth: "360px",
            width: "80%",
            gap: "8px",
          }}
        >
          <TextField
            id="email-input"
            label={email ? null : "ログインID"}
            value={email}
            style={styles.input}
            onChange={(e) => setEmail(e.target.value)}
            InputLabelProps={{ shrink: false }}
            onKeyUp={(e) => {
              if (e.code === "Enter") {
                passwordAuth()
              }
            }}
          />
          <TextField
            id="password-input"
            label={password ? null : "パスワード"}
            type="password"
            style={styles.input}
            onChange={(e) => setPassword(e.target.value)}
            InputLabelProps={{ shrink: false }}
            onKeyUp={(e) => {
              if (e.code === "Enter") {
                passwordAuth()
              }
            }}
          />
        </Box>
        <Box>
          <Button
            onClick={() => passwordAuth()}
            style={{
              ...styles.loginButton,
              backgroundColor:
                email && password ? "#4eb264" : "rgba(78,178,100,0.27)",
            }}
          >
            ログイン
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default LoginView
